var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex-container" }, [
    _c(
      "div",
      { staticClass: "flex-child inner-left" },
      [
        _c("masthead", {
          staticClass: "masthead-title",
          attrs: { title: "Go mobile!", "show-search": false },
        }),
        _vm.mobileAppType === "virtual-phone"
          ? _c("p", { staticClass: "copy" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.mobilePhoneAccessCopyText1) + "\n    "
              ),
            ])
          : _vm._e(),
        _vm.mobileAppType === "corporate-tools"
          ? _c("p", { staticClass: "copy" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.mobileCTAccessCopyText1) + "\n    "
              ),
            ])
          : _vm._e(),
        _c("p", { staticClass: "copy" }, [
          _vm._v("\n      " + _vm._s(_vm.mobileAccessCopyText2) + "\n    "),
        ]),
        _c(
          "div",
          { staticClass: "toggle-button" },
          [
            !_vm.loading
              ? _c("span", [
                  _c(
                    "span",
                    {
                      staticClass: "toggle-wrapper",
                      attrs: {
                        role: "checkbox",
                        "aria-checked": _vm.mobileAccessChoice.toString(),
                        tabindex: "0",
                      },
                      on: {
                        click: _vm.toggle,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar",
                            ])
                          )
                            return null
                          $event.preventDefault()
                          return _vm.toggle.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "toggle-background",
                        class: _vm.backgroundStyles,
                      }),
                      _c("span", {
                        class: _vm.isModal
                          ? "modal-toggle-indicator"
                          : "toggle-indicator",
                        style: _vm.indicatorStyles,
                      }),
                    ]
                  ),
                  _c("p", [_vm._v("Allow Mobile Access")]),
                ])
              : _c("ct-centered-spinner"),
          ],
          1
        ),
      ],
      1
    ),
    _vm.mobileAccessChoice
      ? _c("div", { staticClass: "flex-child inner-right" }, [
          !_vm.usernameExist
            ? _c("div", { staticClass: "inner-content" }, [
                _c("p", { staticClass: "inner-content-title" }, [
                  _vm._v("\n        Create a Username\n      "),
                ]),
                _c(
                  "div",
                  { staticClass: "inner-content-sub-title-content" },
                  [
                    _c(
                      "p",
                      { staticClass: "inner-content-sub-title-content-label" },
                      [_vm._v("\n          Mobile App username\n        ")]
                    ),
                    _c("validation-provider", {
                      attrs: { rules: "min|max" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (v) {
                              return [
                                _c("span", { staticClass: "errorMessage" }, [
                                  _vm._v(_vm._s(v.errors[0])),
                                ]),
                                _c("b-form-input", {
                                  attrs: {
                                    id: "username-input",
                                    size: "sm",
                                    state: _vm.isValid,
                                    placeholder: "Enter your username",
                                  },
                                  model: {
                                    value: _vm.newUsername,
                                    callback: function ($$v) {
                                      _vm.newUsername = $$v
                                    },
                                    expression: "newUsername",
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1398274537
                      ),
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "confirmButton",
                        attrs: { disabled: !_vm.isValid, variant: "info" },
                        on: { click: _vm.canCreateOrUpdate },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              !_vm.toUpdateUsername ? "Confirm" : "Update"
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm.suggestions.length > 0
                      ? _c(
                          "div",
                          { staticClass: "username-state" },
                          [
                            _c("p", { staticClass: "username-state-title" }, [
                              _vm._v("\n            Suggestions\n          "),
                            ]),
                            _vm._l(_vm.suggestions, function (name) {
                              return _c(
                                "div",
                                {
                                  key: name,
                                  staticClass: "username-state-suggestions",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(name) +
                                      "\n          "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.usernameExist
            ? _c("div", { staticClass: "inner-content" }, [
                _c("p", { staticClass: "inner-content-title" }, [
                  _vm._v("\n        Mobile App Login\n      "),
                ]),
                _c("div", { staticClass: "inner-content-sub-title-content" }, [
                  _c("div", { staticClass: "inner-content-display-username" }, [
                    _vm._v("\n          Username:\n          "),
                    _c("span", { attrs: { id: "username-highlight" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.username) +
                          "\n            "
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.editClicked($event)
                            },
                          },
                        },
                        [
                          _c("b-icon-pencil"),
                          _vm._v("\n              Edit\n            "),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _c("p", { class: { "modal-text-size": _vm.isModal } }, [
                  _vm._v(
                    "\n        The mobile app password shares the same password as your web login (what you use to log in to this account)\n      "
                  ),
                ]),
                _c("div", { staticClass: "flex-container qrcode" }, [
                  _c("div", { staticClass: "flex-child qrcode-description" }, [
                    _c("h5", [_vm._v("iPhone")]),
                    _c("p", { class: { "modal-text-size": _vm.isModal } }, [
                      _vm._v(
                        "\n            Please Scan with your phone's camera app to access the iOS application\n          "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-child" },
                    [
                      _c("vue-qrcode", {
                        attrs: {
                          value: _vm.iosTokenLink,
                          options: { width: 200 },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex-container qrcode" }, [
                  _c("div", { staticClass: "flex-child qrcode-description" }, [
                    _c("h5", [_vm._v("Android")]),
                    _c("p", { class: { "modal-text-size": _vm.isModal } }, [
                      _vm._v(
                        "\n            Please Scan with your phone's camera app to access the Android application\n          "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex-child" },
                    [
                      _c("vue-qrcode", {
                        attrs: {
                          value: _vm.androidTokenLink,
                          options: { width: 200 },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }